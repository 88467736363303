import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import './modal.scss'

interface ModalProps {
    title: string
    onCloseClick?: MouseEventHandler
    okTitle?: string
    onOkClick?: MouseEventHandler
    cancelTitle?: string
    onCancelClick?: MouseEventHandler
    children: JSX.Element | JSX.Element[]
}

export const Modal = (props: ModalProps) => {
    const { t } = useTranslation()

    return (
        <div className="modal-container">
            <div className="modal">
                <div className="modal__header">
                    <h3 className="modal__title"> {props.title} </h3>
                    <button className="close-button" onClick={props.onCloseClick}>
                        <img alt="Close button" src="/image/ic_close.svg" />
                    </button>
                </div>

                <div className="modal__body">{props.children}</div>

                {props.onOkClick !== undefined || props.onCancelClick !== undefined ? (
                    <div className="modal__footer">
                        {props.onOkClick ? (
                            <button className="button" type="button" onClick={props.onOkClick}>
                                {props.okTitle ? props.okTitle : t('button_ok')}
                            </button>
                        ) : null}

                        {props.onCancelClick ? (
                            <button className="button button-left-margin" type="button" onClick={props.onCancelClick}>
                                {props.cancelTitle ? props.cancelTitle : t('button_cancel')}
                            </button>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </div>
    )
}
