import { MouseEvent, useEffect, useRef } from 'react'
import { Cover, Vector } from '../../../../../entity'
import './draw-rectangle.scss'

interface EditableEllipseProps {
    width: number
    height: number
    widthRaito: number
    onDrawEnd: CallableFunction
}

export const EditableEllipse = (props: EditableEllipseProps) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const contextRef = useRef<CanvasRenderingContext2D | null>(null)

    var lineOffset = 20
    var anchrSize = 8

    var mousedown = false
    var clickedArea = { box: -1, pos: 'o' }

    var x1 = -1
    var y1 = -1
    var x2 = -1
    var y2 = -1

    var xCenter = -1
    var yCenter = -1

    var xOffset = -1
    var yOffset = -1

    const box = useRef<{ x1: number; y1: number; x2: number; y2: number } | null>(null)
    const cover = useRef<Cover | null>(null)

    useEffect(() => {
        if (!canvasRef.current) {
            return
        }

        const canvas = canvasRef.current

        canvas.width = props.width
        canvas.height = props.height

        canvas.style.width = props.width + 'px'
        canvas.style.height = props.height + 'px'

        const context = canvas.getContext('2d')
        context!!.lineCap = 'round'
        context!!.strokeStyle = 'black'
        context!!.fillStyle = '#fff'
        context!!.lineWidth = 1

        contextRef.current = context

        box.current = { x1: props.width / 2 - 50, y1: props.height / 2 - 50, x2: props.width / 2 + 50, y2: props.height / 2 + 50 }

        onDraw()

        const c = new Cover()
        c.id = Math.random()
        c.type = 2

        cover.current = c

        if (box.current != null && cover.current != null) {
            let position = new Vector()
            position.x = (box.current.x1 + (box.current.x2 - box.current.x1) / 2) / props.widthRaito
            position.y = (box.current.y1 + (box.current.y2 - box.current.y1) / 2) / props.widthRaito

            cover.current.position = position
            cover.current.width = (box.current.x2 - box.current.x1) / props.widthRaito
            cover.current.height = (box.current.y2 - box.current.y1) / props.widthRaito
            cover.current.radius = 0
            cover.current.rotation = 0
            cover.current.points = null

            props.onDrawEnd(cover.current)
        }
    }, [])

    const onMouseDown = (event: MouseEvent) => {
        event.preventDefault()

        mousedown = true

        const { offsetX, offsetY } = event.nativeEvent

        clickedArea = findCurrentArea(offsetX, offsetY)

        x1 = offsetX
        y1 = offsetY
        x2 = offsetX
        y2 = offsetY
    }

    const onMouseMove = (event: MouseEvent) => {
        const { offsetX, offsetY } = event.nativeEvent

        if (mousedown && clickedArea.box === -1) {
            x2 = offsetX
            y2 = offsetY

            onDraw()
        } else if (mousedown && clickedArea.box !== -1) {
            x2 = offsetX
            y2 = offsetY
            xOffset = x2 - x1
            yOffset = y2 - y1
            x1 = x2
            y1 = y2

            if (clickedArea.pos === 'i' || clickedArea.pos === 'tl' || clickedArea.pos === 'l' || clickedArea.pos === 'bl') {
                box.current!!.x1 += xOffset
            }

            if (clickedArea.pos === 'i' || clickedArea.pos === 'tl' || clickedArea.pos === 't' || clickedArea.pos === 'tr') {
                box.current!!.y1 += yOffset
            }

            if (clickedArea.pos === 'i' || clickedArea.pos === 'tr' || clickedArea.pos === 'r' || clickedArea.pos === 'br') {
                box.current!!.x2 += xOffset
            }

            if (clickedArea.pos === 'i' || clickedArea.pos === 'bl' || clickedArea.pos === 'b' || clickedArea.pos === 'br') {
                box.current!!.y2 += yOffset
            }

            onDraw()
        }
        event.preventDefault()
    }

    const onMouseUp = () => {
        clickedArea = { box: -1, pos: 'o' }
        mousedown = false

        if (!mousedown && box.current != null && cover.current != null) {
            let position = new Vector()
            position.x = (box.current.x1 + (box.current.x2 - box.current.x1) / 2) / props.widthRaito
            position.y = (box.current.y1 + (box.current.y2 - box.current.y1) / 2) / props.widthRaito

            cover.current.position = position
            cover.current.width = (box.current.x2 - box.current.x1) / props.widthRaito
            cover.current.height = (box.current.y2 - box.current.y1) / props.widthRaito
            cover.current.radius = 0
            cover.current.rotation = 0
            cover.current.points = null

            props.onDrawEnd(cover.current)
        }
    }

    const onDraw = () => {
        if (contextRef.current && canvasRef.current && box.current != null && (box.current.x2 - box.current.x1) / 2 > 0 && (box.current.y2 - box.current.y1) / 2 > 0) {
            //console.log(box.current.x2 - box.current.x1)
            //console.log(box.current.x2 - box.current.x1)

            contextRef.current!!.clearRect(0, 0, props.width, props.height)
            contextRef.current!!.beginPath()

            xCenter = box.current.x1 + (box.current.x2 - box.current.x1) / 2
            yCenter = box.current.y1 + (box.current.y2 - box.current.y1) / 2

            contextRef.current!!.strokeStyle = 'white'
            contextRef.current!!.fillStyle = 'white'

            contextRef.current!!.ellipse(xCenter, yCenter, (box.current.x2 - box.current.x1) / 2, (box.current.y2 - box.current.y1) / 2, 0, 0, Math.PI * 2)
            contextRef.current!!.stroke()
            contextRef.current!!.fill()

            contextRef.current!!.beginPath()

            contextRef.current!!.strokeStyle = 'yellow'
            contextRef.current!!.lineWidth = 2

            contextRef.current!!.rect(box.current.x1 - anchrSize, box.current.y1 - anchrSize, 2 * anchrSize, 2 * anchrSize)
            contextRef.current!!.rect(box.current.x1 - anchrSize, yCenter - anchrSize, 2 * anchrSize, 2 * anchrSize)
            contextRef.current!!.rect(box.current.x1 - anchrSize, box.current.y2 - anchrSize, 2 * anchrSize, 2 * anchrSize)
            contextRef.current!!.rect(xCenter - anchrSize, box.current.y1 - anchrSize, 2 * anchrSize, 2 * anchrSize)
            contextRef.current!!.rect(xCenter - anchrSize, box.current.y2 - anchrSize, 2 * anchrSize, 2 * anchrSize)
            contextRef.current!!.rect(box.current.x2 - anchrSize, box.current.y1 - anchrSize, 2 * anchrSize, 2 * anchrSize)
            contextRef.current!!.rect(box.current.x2 - anchrSize, yCenter - anchrSize, 2 * anchrSize, 2 * anchrSize)
            contextRef.current!!.rect(box.current.x2 - anchrSize, box.current.y2 - anchrSize, 2 * anchrSize, 2 * anchrSize)
            contextRef.current!!.stroke()
        }
    }

    const findCurrentArea = (x: number, y: number) => {
        if (box.current != null) {
            xCenter = box.current.x1 + (box.current.x2 - box.current.x1) / 2
            yCenter = box.current.y1 + (box.current.y2 - box.current.y1) / 2

            if (box.current.x1 - lineOffset < x && x < box.current.x1 + lineOffset) {
                if (box.current.y1 - lineOffset < y && y < box.current.y1 + lineOffset) {
                    return { box: 1, pos: 'tl' }
                } else if (box.current.y2 - lineOffset < y && y < box.current.y2 + lineOffset) {
                    return { box: 1, pos: 'bl' }
                } else if (yCenter - lineOffset < y && y < yCenter + lineOffset) {
                    return { box: 1, pos: 'l' }
                }
            } else if (box.current.x2 - lineOffset < x && x < box.current.x2 + lineOffset) {
                if (box.current.y1 - lineOffset < y && y < box.current.y1 + lineOffset) {
                    return { box: 1, pos: 'tr' }
                } else if (box.current.y2 - lineOffset < y && y < box.current.y2 + lineOffset) {
                    return { box: 1, pos: 'br' }
                } else if (yCenter - lineOffset < y && y < yCenter + lineOffset) {
                    return { box: 1, pos: 'r' }
                }
            } else if (xCenter - lineOffset < x && x < xCenter + lineOffset) {
                if (box.current.y1 - lineOffset < y && y < box.current.y1 + lineOffset) {
                    return { box: 1, pos: 't' }
                } else if (box.current.y2 - lineOffset < y && y < box.current.y2 + lineOffset) {
                    return { box: 1, pos: 'b' }
                } else if (box.current.y1 - lineOffset < y && y < box.current.y2 + lineOffset) {
                    return { box: 1, pos: 'i' }
                }
            } else if (box.current.x1 - lineOffset < x && x < box.current.x2 + lineOffset) {
                if (box.current.y1 - lineOffset < y && y < box.current.y2 + lineOffset) {
                    return { box: 1, pos: 'i' }
                }
            }
        }

        return { box: -1, pos: 'o' }
    }

    return (
        <div className="draw-rectanlge">
            <canvas ref={canvasRef} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp} onMouseLeave={onMouseUp} height={props.height} width={props.width}></canvas>
        </div>
    )
}
