import { Vector } from '../../../../../entity'

interface FreehandDrawingViewProps {
    points: Vector[]
    ratioWidth: number
    ratioHeight: number
    width: number
    height: number
    left: number
    top: number
}

export const FreehandDrawingView = (props: FreehandDrawingViewProps) => {
    return (
        <>
            {(() => {
                let objects = []

                if (props.points != null && props.points.length !== 0) {
                    let lineData = ''

                    let firstPos = 0
                    let lastPos = 0
                    let spacerPos = 0

                    //console.log(props.points)

                    for (let i = 0; i < props.points.length; i++) {
                        const point = props.points[i]

                        if (point.flags !== '[SPACER]') {
                            lineData += Number((point.x * props.ratioWidth).toFixed(0)) + ',' + Number((point.y * props.ratioWidth).toFixed(0))
                            lineData += ' '
                        } else {
                            //csak ahhoz kell hogy a path vége szép lekerekített legyen mint mobilon

                            firstPos = spacerPos
                            lastPos = i - 1
                            spacerPos = i + 1

                            var circleStyle = {
                                padding: 0,
                                margin: 0,
                                display: 'inline-block',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                width: 20,
                                height: 20,
                                left: props.points[firstPos].x * props.ratioWidth - 10,
                                top: props.points[firstPos].y * props.ratioWidth - 9,
                            }

                            objects.push(<div style={{ position: 'absolute', ...circleStyle, paddingLeft: '0px', paddingTop: '0px' }}></div>)

                            circleStyle = {
                                padding: 0,
                                margin: 0,
                                display: 'inline-block',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                width: 20,
                                height: 20,
                                left: props.points[lastPos].x * props.ratioWidth - 10,
                                top: props.points[lastPos].y * props.ratioWidth - 9,
                            }

                            objects.push(<div style={{ position: 'absolute', ...circleStyle, paddingLeft: '0px', paddingTop: '0px' }}></div>)

                            const path = (
                                <div style={{ position: 'absolute', paddingLeft: '0px', paddingTop: '0px' }}>
                                    <svg width={props.width} height={props.height} fill="#ffffff" stroke="#ffffff">
                                        <polyline points={lineData} stroke="white" strokeWidth="20" fill="none" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            )

                            lineData = ''

                            objects.push(path)
                        }
                    }
                }

                return objects
            })()}
        </>
    )
}
