import { useDispatch, useSelector } from 'react-redux'
import { State } from '../../state'
import { ChangeEvent, MouseEvent, KeyboardEvent } from 'react'
import { SearchField } from '../../component/field'
import { WarrantiesTable } from './component'
import { GET_WARRANTIES_ACTIONS } from '../../action/warranties/warranties.constants'
import { getWarranties } from '../../action'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Segment } from '../../enum/segment.enum'
import { MessageModal } from '../../component/modal'
import './warranties.page.scss'

interface WarrantiesPageProps {}

export const WarrantiesPage = (props: WarrantiesPageProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { t } = useTranslation()
    const { vin } = useParams()

    const { warranties, search, warrantiesNotFound } = useSelector((state: State) => state.warranties)

    const onSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault()

        dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_SEARCH, search: event.target.value })

        if (event.target.value.length === 0) {
            await getWarranties(vin ?? '')
        }
    }

    const onSearchButtonClick = async (event: MouseEvent | KeyboardEvent) => {
        event.preventDefault()

        await getWarranties(vin ?? '')
    }

    return (
        <main className="page page-warranties">
            <div className="main-div">
                <div className="navigation-bar-div">
                    <h1>
                        {t('warranties_title')} &nbsp;{vin}
                    </h1>
                    <p>{warranties.length !== 0 && warranties[0].segment === Segment.MARINE ? t('warranties_engine_description').replace('{VIN szám}', vin ?? '') : t('warranties_description').replace('{VIN szám}', vin ?? '')}</p>
                </div>

                <div className="search-bar-div">
                    <SearchField value={search} onSearch={onSearchButtonClick} onChange={onSearchChange} placeholder={t('search_placeholder')} />

                    <button className="button" onClick={onSearchButtonClick}>
                        {t('search_button')}
                    </button>
                </div>

                <WarrantiesTable vin={vin ?? ''} />
            </div>

            {warrantiesNotFound ? (
                <MessageModal
                    title={t('delete_warranty_success_title')}
                    message={t('delete_warranty_success_message')}
                    okTitle={t('button_ok')}
                    onCloseClick={() => {
                        dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_WARRANTY_NOT_FOUND, value: false })

                        navigate('/')
                    }}
                    onOkClick={async () => {
                        dispatch({ type: GET_WARRANTIES_ACTIONS.GET_WARRANTIES_WARRANTY_NOT_FOUND, value: false })

                        navigate('/')
                    }}
                />
            ) : null}
        </main>
    )
}
